
import Vue from "vue";
import Component from "vue-class-component";
import { Auth } from "@/store/modules";

@Component({ name: "Login" })
export default class extends Vue {
  done = false;
  error = '';

  async logout() {
    this.done = false;
    try {
      await Auth.doLogout({ noRedirect: true });
      this.$router.push('/');
      window.location.href = process.env.VUE_APP_APP_URL as string;
    } catch (err) {
      this.error = err.message;
      console.log(this.error);
      this.$router.push('/');
    }
    this.done = true;
  }

  mounted() {
    this.logout();
  }
}
